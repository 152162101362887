* {
  background-color: rgba(191, 230, 214, 0.905);
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#servicing {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
  margin-top: -30px;
  background-color: rgb(58, 121, 123);
  color: white;
}
#homeLogo {
  max-width: 100%;
  max-height: 40%;
  
}
#aboutPic {
  max-width: 70%;
}
#aboutContainer {
  margin-top: 50px;
}
#aboutText {
  /* background-color: rgb(213, 229, 234); */
  
  padding: 50px;
  font-size: 23px;
  font-weight: 500;
  max-width: 30%;
  border-radius: 20px;
  box-shadow: 0px 5px 3px 0px rgba(149, 149, 149, 0.92);
}

#footer {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  background-color: transparent;
}
#contactLinks {
  /* text-decoration: none; */
  color: black;
}
#contactLinks2 {
  text-decoration: none;
  color: black;
}
#contactLinksPic {
  color: rgb(225, 213, 185);
}
#listText {
  /* background-color: rgb(58, 121, 123); */
  color: white;
  padding-bottom: 20px;
}
#test1pic {
  max-width: 100%;
  margin-top: -20px;
}
#test2pic {
  max-width: 100%;
  max-height: 100%;
}
#text3pic {
  max-width: 100%;
}
.card-title {
  font-size: 30px;
  font-weight: 600;
  color: white;
  background-color: rgba(227, 226, 219, 0.918);
}
#aHere {
  background-color: rgba(227, 226, 219, 0.918);
}
.card-text {
  color: white;
  max-width: 70%;
  background-color: rgba(227, 226, 219, 0.918);
}
.card-text2 {
    background-color: rgba(227, 226, 219, 0.918);
  /* background-color: rgba(227, 226, 219, 0.918); */

}
.mb-2 {
    background-color: rgba(227, 226, 219, 0.918);
  /* background-color: rgba(227, 226, 219, 0.918); */

}
#listText {
  font-size: 30px;
}

#imageLage {
  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 4;
  -moz-column-gap: 0px;
  column-count: 4;
  column-gap: 0px;
  position: relative;
}
#imageLage img {
  padding: 5px;
  width: 100% !important;
  height: auto !important;
}

#investContain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
#investCards {
  margin: 20px;
  clip-path: rectangle();
}
#portServ {
  margin-bottom: 50px;
}
.engImg {
  max-width: 100%;
}
#carouselExampleSlidesOnly {
  padding-bottom: -500px;
}
#navLogo {
  display: flex;
  justify-content: center;
  max-height: 400px;
  max-width: 400px;
  position: relative;
}
#navRelative {
  position: relative;
}
#navLogoDT {
  max-height: 10%;
  max-width: 10%;
  position: relative;
}
.carousel-item {
  max-width: 100%;
  margin: 10px;
}
.navbar {
  font-size: 30px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.nav-link {
  position: relative;
}
a {
  position: relative;
}
nav {
  position: relative;
}
#navButtons {
  padding-right: 15%;
}
#NavLogo {
  max-width: 200px;
  position: relative;
}
#dropdownStyle {
  border-color: transparent;
  font-size: 30px;
}
.card {
  border-color: transparent;
  
}

#addy {
  font-size: 40px;
  color: rgb(225, 213, 185);
}
#contactText {
  max-width: 50%;
  margin-left: 50%;
}
#con {
  margin-top: 20px;
  max-width: 60%;
  
}
#contactLabels {
  margin-bottom: 10px;
}
#contactbtn {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.774);
  border: 2px;
  border-color: rgba(92, 92, 92, 0.407);
  background-color: rgba(58, 121, 123, 0.47);
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-decoration: none;
  text-shadow: 1px 1px 1px #e6d9d9;
}
#contactbtnS {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.774);
  border: 2px;
  border-color: rgba(92, 92, 92, 0.407);
  background-color: rgba(58, 121, 123, 0.47);
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-decoration: none;
  text-shadow: 1px 1px 1px #e6d9d9;
}
input {
  border-radius: 10px;
  border-color: #4d4d4d;
}
textarea {
  border-radius: 10px;
  border-color: #4d4d4d;
  max-width: 100%;
}
#bookLink {
  font-size: 40px;
  color: black;
  border: 2px;
  border-color: black;
  background-color: rgba(58, 121, 123, 0.676);
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  max-width: 30%;
}
#conCon {
  display: flex;
  flex-direction: column;
}
#ContactFormCon {
  margin-bottom: -40px;
  display: flex;
  justify-content: center;
}
#homeContain {
  margin-bottom: -90px;
}
.mobile-only {
  display: none;
}
#navBtnHeader {
  border-style: none;
  /* background-color: transparent; */
  max-width: 1%;
}
#socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#socImgPad {
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(58, 121, 123, 0.676);
}
#homeBack {
  max-width: 70%;
}
p {
  font-size: 25px;
  font-weight: 500;
  color: black;
  text-align: center;
  background-color: rgba(227, 226, 219, 0.918);
  border-radius: 20px;
  padding:10px;
  position: relative;
}
.testimony1 {
  background-color: rgba(227, 226, 219, 0.918);
  font-size: 15px;
  font-weight: 600;
  max-width: 100%;
  margin-bottom: 40px;
  margin-left: 20%;
  margin-right: 20%;
  border-radius: 20px;
  box-shadow: 0px 5px 5px 0px rgba(149, 149, 149, 0.92);
  position: relative;
}
.testimony2 {
  background-color: rgba(227, 226, 219, 0.918);
  font-size: 15px;
  font-weight: 600;
  margin-left: 20%;
  margin-right: 20%;
  max-width: 100%;
  margin-bottom: 40px;
  border-radius: 20px;
  box-shadow: 0px 5px 5px 0px rgba(149, 149, 149, 0.92);
  position: relative;
}
#testContain {
 position: relative;
 background-color: transparent;
}

#reviewLink {
  font-size: 45px;
  color: black;
  border: 2px;
  border-color: rgba(92, 92, 92, 0.638);
  background-color: rgba(33, 170, 147, 0.313);
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-decoration: none;
  text-shadow: 1px 1px 1px #e6d9d9;
  position: relative;
}
#bookLink {
  font-size: 40px;
  color: black;
  border: 2px;
  border-color: black;
  background-color: rgba(58, 121, 123, 0.676);
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  max-width: 30%;
}
h2 {
  color: rgb(58, 121, 123);
  font-size: 60px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
h3 {
  color: rgb(58, 121, 123);
  font-size: 40px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
h1 {
  font-weight: 900;
  font-family: 'Playfair Display SC', serif;
  font-size: 80px;
  color: rgb(58, 121, 123);
  position: relative;
  background-color: transparent;
}
h5 {
  color: rgb(58, 121, 123);
  
}
#faq {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px;
  background-color: transparent;
}
.faqQ {
  color: rgb(58, 121, 123);
  font-size: 35px;
  position: relative;
  background-color: transparent;
}
#faqA {
  background-color: transparent;
}
#faqP {
  max-width: 50%;
  position: relative;
  background-color: transparent;
}
#faqHead {
  font-size: 55px;
  position: relative;
}
#footImg {
  max-width: 70%;
}
#byoStyle {
  color: black;
}

h2 {
  font-weight: 900;
  font-family: 'Playfair Display SC', serif;
  position: relative;
}

#hairstory {
  max-width: 39%;
  
}
#aHere {
  color: black;
  background-color: transparent;
  position: relative;
}
#makeItDif {
  background-color: rgba(191, 230, 214, 0.905);

}
#booking {
  background-color: rgba(227, 226, 219, 0.918);
  border-radius: 10px;
  /* background-color: rgba(248, 250, 215, 0.924);   */
}

#tabFont {
  font-size: 50px;
}

#prodCon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
}
#prodLinks {
  background-color: rgba(227, 226, 219, 0.918);
  color: black;
}
#prodzBackz {
  background-color: rgba(227, 226, 219, 0.918);
}
#products {
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
  max-width:100%;
  
}

#hairstory {
  max-width: 45%;
}
.linktree {
  forced-color-adjust: blue;
}

.cardSection {
  align-items: center;
}

.bioPic {
  max-width: 100%;
  border-style: solid;
  border-color:rgba(47, 79, 79, 0.905);
  border-radius: 10px;
  margin: 10px;
  position: relative;
}

.bioText {
  background-color: rgba(227, 226, 219, 0.918);
  max-width: 70%;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color:rgba(47, 79, 79, 0.502);
  border-radius: 10px;
  font-size: 25px;
  color:rgb(20, 36, 36);
  font-family: 'Abel', sans-serif;
  box-shadow: 3px 3px 3px 1px rgba(47, 79, 79, 0.275);
}
#bioHello {
  background-color: rgba(227, 226, 219, 0.918);
}
.imgz {
  max-height: 20%;
  max-width: 32.1%;
  border-style: solid;
  border-color:rgba(47, 79, 79, 0.905);
  border-radius: 10px;
  margin: 10px;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  -webkit-transition-timing-function: ease;
}

.imgz:hover {
  transform: scale(1.35); 
}

.col {
  display:flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.card {
  text-align: center;
  margin: 10px;
  max-width: 500px;
  border-style: solid;
  border-width: 1px;
  border-color:rgba(47, 79, 79, 0.905);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(47, 79, 79, 0.359);
  background-color: transparent;
  position: relative;
}
center{ 
 
}
.card-body {
  max-width: 100%;
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
  background-color: rgba(227, 226, 219, 0.918);

}
.card-body2 {
  max-width: 100%;
  border-radius: 10px;
  text-align: center;
  background-color: rgba(227, 226, 219, 0.918);

}

.card-img-top {
  height: 200px;
  max-height: 200px;
  max-width: 60%; 
  padding: 5%;
  margin-left:20%;
  background-color: transparent;
}

.card-img-top2 {
  height: 200px;
  max-height: 200px;
  max-width: 60%; 
  padding: 5%;
  margin-left: 20%;
  background-color: transparent;
}

.card-img-top3 {
  height: 200px;
  max-height: 200px;
  max-width: 70%; 
  padding-left: 5%;
  padding-right: 5%;
  margin-left: 15%;
  background-color: transparent;

}
.card-text {
  padding-left: 1px; 
  font-family: 'Abel', sans-serif;
  color: black;
  text-align: center;
}
.card-text2 {
  padding-left: 1px; 
  font-family: 'Abel', sans-serif;
  color: black;
  text-align: center;
}
.card-title {
  font-family: 'Josefin Sans', sans-serif;
  color: black;
}
#servicesContain{
  display: flex;
  justify-content: center;
  text-align: center;
}
.linktree {
  background-color: rgba(227, 226, 219, 0.918);
}
svg {
  background-color: rgba(227, 226, 219, 0.918);

}
.bi-facebook {
background-color: rgba(227, 226, 219, 0.918);
color: black;
}
.bi-instagram {
  background-color: rgba(227, 226, 219, 0.918);
  color: black;
}
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */



@media (max-width: 1500px) {
  * {
    font-family: Arial, Helvetica, sans-serif;

    /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  }
  .App {
    text-align: center;
  }
  .App-header {
    background-color: #282c34;
    min-height: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  #indexBack {
    max-width: 100%;
  }
  .mobile-only {
    display: block;
    /* background-image: url("../src/images/GalleriesCOM/FamilyCOM/DSC_7986.jpg"); */
  }
  .desktop-only {
    display: none;
  }
  #servicing {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 20px;
    margin-top: -30px;
    background-color: rgb(58, 121, 123);
    color: white;
  }
  #homeLogo {
    max-width: 100%;
    max-height: 40%;
  }
  #aboutPic {
    max-width: 90%;
  }
  #aboutContainer {
    margin-top: 50px;
  }
  #aboutText {
    background-color: rgb(213, 229, 234);
    padding: 15px;
    font-size: 21px;
    font-weight: 500;
    max-width: 100%;
    border-radius: 20px;
  }
  .bottomDiv {
    position: relative;
    margin-top: -30px;
    bottom: 0px;
    left: 0px;
    margin-bottom: 10px;
  }
  #aboutPic2 {
    max-width: 90%;
  }
  #aboutContainer2 {
    margin-top: -20px;
  }
  #aboutText2 {
    background-color: rgb(213, 229, 234);
    padding: 15px;
    font-size: 27px;
    font-weight: 500;
    max-width: 80%;
    border-radius: 20px;
  }
  .bottomDiv2 {
    position: relative;
    margin-top: -0px;
    bottom: 10px;
    left: 35px;
  }
  #aboutPic3 {
    max-width: 90%;
  }
  #aboutContainer3 {
    margin-top: -30px;
  }
  #aboutText3 {
    background-color: rgb(213, 229, 234);
    padding: 15px;
    font-size: 27px;
    font-weight: 500;
    max-width: 80%;
    border-radius: 20px;
  }
  .bottomDiv3 {
    position: relative;
    margin-top: -20px;
    bottom: -10px;
    left: 40px;
  }
  #aboutPic4 {
    max-width: 90%;
  }
  #aboutContainer4 {
    margin-top: -420px;
  }
  #aboutText4 {
    background-color: rgb(213, 229, 234);
    padding: 15px;
    font-size: 27px;
    font-weight: 500;
    max-width: 80%;
    border-radius: 20px;
  }
  .bottomDiv4 {
    position: relative;
    margin-top: -10px;
    bottom: 0px;
    left: 40px;
  }
  #wedPicz {
    max-width: 90%;
  }
  #wedContain {
    display: flex;
    justify-content: center;
  }
  #footer {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: transparent;
  }
  #footName {
    font-size: 35px;
  }
  #test1text {
    margin: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
  }
  #reviewLink {
    font-size: 35px;
    color: black;
    border: 2px;
    border-color: black;
    background-color: rgba(58, 121, 123, 0.529);
    border-style: solid;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
  }

  #listText {
    /* background-color: rgba(191, 230, 214, 0.905); */
    color: white;
    padding-bottom: 20px;
  }
  #test1pic {
    max-width: 100%;
    margin-top: -20px;
  }
  #test2pic {
    max-width: 100%;
    max-height: 100%;
  }
  #text3pic {
    max-width: 100%;
  }
  .card-title {
    font-size: 30px;
    font-weight: 600;
  
  }
  .card-text {
  
    max-width: 70%;
  }
  #listText {
    font-size: 30px;
  }
  #galleryOpts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
  .gallPhotos {
    max-width: 100%;
    clip-path: circle();
  }
  #imageLage {
    -webkit-column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-count: 2;
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 0px;
  }
  #imageLage img {
    /* Just in case there are inline attributes */
    padding: 5px;
    width: 100% !important;
    height: auto !important;
  }
  #wedNameMobile {
    font-size: 40px;
  }
  #investCards {
    margin: 20px;
    clip-path: rectangle();
  }
  #portServ {
    margin-bottom: 10px;
    font-size: 50px;
  }
  .engImg {
    max-width: 100%;
  }
  #carouselExampleSlidesOnly {
    padding-bottom: -500px;
  }
  #navLogo {
    display: flex;
    justify-content: center;
    max-height: 350px;
    max-width: 350px;
    background-color: tr
    
  }
  ul {
    background-color: transparent;
  }
  a {
    background-color: transparent;
  }
  #closeBtn {
    background-color: transparent;
  }
  .offcanvas-header {
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    background-color: rgb(191, 230, 214);
  }
  .offcanvas-body {
    background-color: rgb(191, 230, 214);
  }
  #navLogoDT {
    max-height: 30%;
    max-width: 30%;
  }
  .carousel-item {
    max-width: 100%;
    margin: 10px;
  }
  #navBarStyle {
    /* background-color: rgba(191, 230, 214, 0.905); */
  }
  .navbar {
    /* background-color: rgba(191, 230, 214, 0.905); */
    font-size: 30px;
    display: flex;
    flex-direction: row;
  }
  .container-fluid {
    background-color: transparent;
  }
  .nav-link {
    /* background-color: rgba(191, 230, 214, 0.905); */
  }
  #navRelative {
    background-color: transparent;
  }
  #navButtons {
    padding-right: 0%;
    /* background-color: rgba(191, 230, 214, 0.905); */
  }
  #NavLogo {
    max-width: 300px;
    background-color: transparent;
  }
  #navLogo {
    background-color: transparent;
  }
  #dropdownStyle {
    border-color: transparent;
    font-size: 30px;
  }
  .card {
    border-color: transparent;
    background-color: rgba(217, 219, 216, 0.375);
    margin: 5%
  }
  #contactContain {
    display: flex;
    justify-content: center;
  }
  #emailMess {
    margin-top: 20px;
  }
  #contactStyle {
    font-size: 20px;
    font-weight: 600;
    color: rgb(225, 213, 185);
  }
  #addy {
    font-size: 20px;
    font-weight: 600;
    color: rgb(225, 213, 185);
  }
  #con {
    margin-top: 150px;
  }
  #homeBack {
    max-width: 100%;
  }
  .testimony1 {
    background-color: rgba(227, 226, 219, 0.918);
    color: white;
    /* padding: 15px; */
    font-size: 20px;
    font-weight: 600;
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .testimony2 {
    background-color: rgba(227, 226, 219, 0.918);
    font-size: 5px;
    font-weight: 600;
    
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
  #testH1 {
    font-size: 50px;
    
  }
  .bioText {
    font-size: 18px;
    max-width: 80%;
  }
  .bioPic {
    max-width: 90%;
  }
  #testContain {
  margin-left: 8%;
  max-width: 100%;
  }
  #aHere {
    font-family: 'Josefin Sans', sans-serif;
    background-color: rgba(191, 230, 214, 0.905);
  }
  h2 {
    color: rgb(58, 121, 123);
    font-size: 45px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  h3 {
    color: rgb(58, 121, 123);
    font-size: 40px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
  h1 {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 80px;
  }
  h4 {
    font-size: 25px;
  }
  h5 {
    color: rgb(58, 121, 123);
  }
  #faq {
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 5px;
  }
  .faqQ {
    color: rgb(58, 121, 123);
    font-size: 30px;
  }
  #faqP {
    max-width: 100%;
  }
  #faqHead {
    font-size: 50px;
  }
  p {
    font-size: 21px;
    font-weight: 500;
    background-color: rgba(227, 226, 219, 0.918);
  }
  #footImg {
    max-width: 80%;
  }
  #contactbtn {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.774);
    border: 2px;
    border-color: rgba(92, 92, 92, 0.407);
    background-color: rgba(58, 121, 123, 0.47);
    border-style: solid;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-shadow: 1px 1px 1px #e6d9d9;
  }
  #contactbtnS {
    font-size: 40px;
    color: rgba(0, 0, 0, 0.774);
    border: 2px;
    border-color: rgba(92, 92, 92, 0.407);
    background-color: rgba(58, 121, 123, 0.47);
    border-style: solid;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-shadow: 1px 1px 1px #e6d9d9;
  }
  #testContain {
 margin: 5%;
 max-width: 100%;
  }

  #contactLinks {
    background-color: transparent;
  }
  #contactLinks2 {
    background-color: transparent;
  }
  #footBtm {
    background-color: transparent;
  }
  #foot2 {
    background-color: transparent;
  }
  .card-img-top {
    height: 200px;
    max-height: 200px;
    max-width: 80%; 
    padding: 5%;
    margin-left:10%;
    background-color: rgba(217, 219, 216, 0.061);
  }
  
  .card-img-top2 {
    height: 200px;
    max-height: 200px;
    max-width: 60%; 
    padding: 5%;
    margin-left: 20%;
    background-color: rgba(217, 219, 216, 0.061);
  }
  
  .card-img-top3 {
    height: 200px;
    max-height: 200px;
    max-width: 70%; 
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 15%;
    background-color: rgba(217, 219, 216, 0.061);
  
  }
  .card-body2 {
    max-width: 100%;
     
    border-radius: 10px;
    text-align: center;
    background-color: rgba(227, 226, 219, 0.918);
  
  }

  .card-body {
    max-width: 100%;
     
    border-radius: 10px;
    text-align: center;
    background-color: rgba(227, 226, 219, 0.918);
  
  }
  #contactText {
    max-width: 50%;
    margin-left: 50%;
  }
  #con {
    margin-top: 20px;
    max-width: 100%;
    
  }
  #contactLabels {
    margin-bottom: 10px;
  }
  #contactbtn {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.774);
    border: 2px;
    border-color: rgba(92, 92, 92, 0.407);
    background-color: rgba(58, 121, 123, 0.47);
    border-style: solid;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-shadow: 1px 1px 1px #e6d9d9;
  }
  #contactbtnS {
    font-size: 50px;
    color: rgba(0, 0, 0, 0.774);
    border: 2px;
    border-color: rgba(92, 92, 92, 0.407);
    background-color: rgba(58, 121, 123, 0.47);
    border-style: solid;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    text-decoration: none;
    text-shadow: 1px 1px 1px #e6d9d9;
  }
  .map {
    max-width: 90%;
  }
#closeBtn {
  background-color: transparent;
}
a {
  background-color: transparent;
}
}


